import { parameterApi } from "@/api/parameterApi";

export const loadTransportCompanies = async ({ commit }) => {
  try {
    let { data } = await parameterApi.loadTransportCompanies()
    commit('setDataTables', data, false)
  } catch (error) {

    console.log(error)
    if (error.response.status == 422) {
      commit('setDataTables', [], false)
    }
  }
}

export const createTransportCompanies = async (_, body) => {
  try {
    let { data } = await parameterApi.createTransportCompanies(body)
    return { ok: true, id: data.id }
  } catch (error) {
    console.log(error)

    return { ok: false, message: error.response.data.message }
  }
}
export const loadBankTransportCompanies = async (_, id) => {
  try {
    let { data } = await parameterApi.loadBankTransportCompanies(id)
    return data

  } catch (error) {
    console.log(error)

  }
}

export const createBnak = async (_, body) => {
  try {
   await parameterApi.createBankTransportCompanies(body)
    return { ok: true } 
  } catch (error) {
    console.log(error)
    return{ ok: false, message: error.response.data.message}
  }
}

export const updateTransportCompanies = async (_, body) => {
  try {
    await parameterApi.updateTransportCompanies(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}
export const updateOrSaveNewContract = async (_, body) => {
  try {
    await parameterApi.updateOrSaveNewContract(body)
    return { ok: true }
  } catch (error) {
    return { ok: false, message: error.response.data.message }
  }
}
export const updateBankTransportCompany = async (_, body) => {
  try {
    await parameterApi.updateBankTransportCompany(body)
    return  { ok: true } 
  } catch (error) {
    return{ ok: false, message: error.response.data.message}
  }
}
export const deleteBankTransportCompany = async (_, id) => {
  try {
    await parameterApi.deleteBankTransportCompany(id)
    return { ok: true }
  } catch (error) {
    return { ok: false }

  }
}
export const companyLoadTariffList = async (_, id) => {
  try {
    let { data } = await parameterApi.companyLoadTariffList(id)
    return data
  } catch (error) {
    console.log(error)

  }
}
export const companyCargoGeneratorRelation = async (_, id) => {
  try {
    let { data } = await parameterApi.companyCargoGeneratorRelation(id)
    return data
  } catch (error) {
    console.log(error)

  }
}
export const getcontractCompanyResult = async (_, id) => {
  try {
    let { data } = await parameterApi.getcontractCompanyResult(id)
    return data
  } catch (error) {
    console.log(error)

  }
}
export const getobtainTransportCompanyCalculations = async (_, id) => {
  try {
    let { data } = await parameterApi.getobtainTransportCompanyCalculations(id)
    return data
  } catch (error) {
    console.log(error)

  }
}
export const getobtainContractsMaestroTransporterShipments = async (_, id) => {
  try {
    let { data } = await parameterApi.getobtainContractsMaestroTransporterShipments(id)
    return data
  } catch (error) {
    console.log(error)

  }
}
