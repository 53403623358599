const isAuthenticatedGuard = async( to, from, next ) => {
    return new Promise( () => {
        let token = localStorage.getItem('token_session')
        if (token !== null) {
            next()
        } else {
            next({ name: 'formSession' })
        }
    })
}
export default isAuthenticatedGuard
