import { parameterApi } from "@/api/parameterApi";

export const loadCustomer = async ({ commit }) => {
    try {
        let { data } = await parameterApi.dataTableClients();
        commit('setDataTables', data, false)
    } catch (error) {
    console.log(error)
    if (error.response.status == 422) {
            commit('setDataTables', [], false)
        }
    }
}

export const createCustomer = async (_, data) => {
    try {
        await parameterApi.createCustomer(data)
        return { ok: true }
    } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
    }
}

export const editCustomer = async (_,data) => {
    try {
        await parameterApi.editCustomer(data);
        return { ok: true }
    } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
    }
}