import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './css/styles/styles.css'

const app = createApp(App)

const backendURL = process.env.VUE_APP_BACKEND_URL.slice(0, -4);

if (backendURL.endsWith('/')) {
    app.config.globalProperties.$urlImg = backendURL + 'files';
} else {
    app.config.globalProperties.$urlImg = backendURL + '/files';
}

app.config.globalProperties.$regex = /\/thumbnails\//

app.use(store)
app.use(router)
app.mount('#app')