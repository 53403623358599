
import { parameterApi } from "@/api/parameterApi";

export const loadTransportCompany = async ({ commit }) => {
  try {
    let { data } = await parameterApi.loadTransportCompany()
    commit('TransportCompany', data)
  } catch (error) {
    console.log(error)
    if (error.response.status == 422) {
      commit('TransportCompany', [])
    }
  }
}


export const loadMasterDrivers = async ({ commit }) => {
  try {
    let { data } = await parameterApi.masterDrivers()
    commit('MasterDrivers', data)
  } catch (error) {
    console.log(error)
    if (error.response.status == 422) {
      commit('MasterDrivers', [])
    }
  }
}

export const loadVehicle = async ({ commit }) => {
  try {
    let { data } = await parameterApi.masterVehicles()

    data = Object.values(data).reverse()


    for (let i = 0; i < data.length; i++) {
      data[i].brand = data[i].brand + ' ' + `( ${data[i].licensePlate} )`
    }


    commit('vehicle', data)
  } catch (error) {
    console.log(error)
    if (error.response.status == 422) {
      commit('vehicle', [])
    }
  }
}
export const loadGenerator = async ({ commit }) => {
  try {
    let { data } = await parameterApi.masterLoadGenerator()
    commit('generator', data)
  } catch (error) {
    console.log(error)
    if (error.response.status == 422) {
      commit('generator', [])
    }
  }
}

export const findByKeysList = async (_, body) => {
  try {
    let { data } = await parameterApi.findByKeysList(body)
    return data
  } catch (error) {
    console.log(error)
  }
}

