import { parameterApi } from "@/api/parameterApi";

export const loadRatesTables = async ({ commit }) => {
    try {
        let { data } = await parameterApi.loadRatesTables()
        commit('setDataTables', data, false)
    } catch (error) {
        console.log(error)
        if (error.response.status == 422) {
            commit('setDataTables', [], false)
        }
    }
}
export const createRates = async (_, body) => {
    try {
        await parameterApi.createRates(body)
        return { ok: true }
    } catch (error) {
        console.log(error)
        return { ok: false, message: error.response.data.message }

    }
}
export const UpdateRates = async (_, body) => {
    try {
        await parameterApi.UpdateRates(body)
        return { ok: true }
    } catch (error) {
        console.log(error)
        return { ok: false, message: error.response.data.message }

    }
}
export const saveImgVehicle = async (_, body) => {
    try {
        await parameterApi.saveImgVehicle(body)
        return { ok: true }
    } catch (error) {
        console.log(error)
    }
}
export const updateStatusTariffs = async (_, body) => {
    try {
        await parameterApi.updateStatusTariffs(body)
        return { ok: true }
    } catch (error) {
        console.log(error)
    }
}

export const getTaxesChargesByIdAll = async () => {
    try {
        let { data } = await parameterApi.getTaxesChargesByIdAll()
        return data
    } catch (error) {
        console.log(error)
    }
}
export const updateTaxChargePercentage = async (_, body) => {
    try {
        await parameterApi.updateTaxChargePercentage(body)
        return { ok: true }
    } catch (error) {
        console.log(error)
    }
}

export const getRateTypeVehicle = async () => {
    try {
        const { data } = await parameterApi.getRateTypeVehicle()
        return data
    } catch (error) {
        console.log(error)
    }
}
export const saveOrUpdateRateVehicleType = async (_, body) => {
    try {
        await parameterApi.saveOrUpdateRateVehicleType(body)
        return { ok: true }
    } catch (error) {
        console.log(error)
        return { ok: false, message: error.response.data.message }
    }
}
