import { parameterApi } from "@/api/parameterApi";

export const loadBodies = async ({ commit }) => {
  try {
    let { data } = await parameterApi.loadBodies()
    commit('setDataTables', data, false)
  } catch (error) {
    console.log(error)
    if (error.response.data.message == "No se encontró el elemento solicitado.") {
      commit('setDataTables', [], false)
    }
  }
}
export const loadPhoto = async (_, id) => {
  try {
    let { data } = await parameterApi.loadBodiesPhoto(id)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const createBodies = async (_, infoBodies) => {
  try {
    let { data } = await parameterApi.createBodies(infoBodies)
    return { ok: true, id: data.id }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}

export const updateBodies = async (_, infoBodies) => {
  try {
    await parameterApi.updateBodies(infoBodies)
    return { ok: true, }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}
export const createPhoto = async (_, photo) => {
  try {
    await parameterApi.createBodiesPhoto(photo)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}
export const updatePhoto = async (_, photo) => {
  try {
    await parameterApi.updateBodiesPhoto(photo)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}
export const createObservation = async (_, observation) => {
  try {
    await parameterApi.createBodiesObservation(observation)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}
export const deleteObservation = async (_, id) => {
  try {
    await parameterApi.deleteBodiesObservation(id)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}
export const deleteInsurance = async (_, id) => {
  try {
    await parameterApi.deleteBodiesInsurance(id)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}
export const UpdateObservation = async (_, data) => {
  try {
    await parameterApi.updateBodiesObservation(data)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}
export const UpdateInsurance = async (_, data) => {
  try {
    await parameterApi.updateBodiesInsurance(data)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}

export const createInsurance = async (_, insurance) => {
  try {
    await parameterApi.createBodiesInsurance(insurance)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}
export const loadObservation = async (_, id) => {
  try {
    let { data } = await parameterApi.loadBodiesObservation(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const getMasterTransportVehicleActive = async (_, id) => {
  try {
    let { data } = await parameterApi.getMasterTransportVehicleActive(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const loadInsurance = async (_, id) => {
  try {
    let { data } = await parameterApi.loadBodiesInsurance(id)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const companyBodyRelations = async (_, body) => {
  try {
    await parameterApi.companyBodyRelations(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}
export const bodyVehicleRelation = async (_, body) => {
  try {
    await parameterApi.bodyVehicleRelation(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}
export const bodyVehicleRelationUpdate = async (_, body) => {
  try {
    await parameterApi.bodyVehicleRelationUpdate(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}
export const companyBodyRelationsUpdate = async (_, body) => {
  try {
    await parameterApi.companyBodyRelationsUpdate(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
  }
}
