import axiosApi from '@/api/axios'
const parameterApi = {
  approveRemittance: (body) => axiosApi.put(`remittance/approveRemittance`, body),
  beneficiaryPaymentBalanceummaryPayee: (id) => axiosApi.get(`contracts/advancesBeneficiaryPaymentBalance/${id}`),
  BeneficiaryRelations: (body) => axiosApi.post(`beneficiaryPaymentCompanyRelation/save`, body),
  bodyVehicleRelation: (body) => axiosApi.post(`bodyVehicleRelation/save`, body),
  bodyVehicleRelationUpdate: (body) => axiosApi.put(`bodyVehicleRelation/update`, body),
  cancelAdvanceStatus: (body) => axiosApi.put(`remittance/cancelAdvanceStatus`, body),
  cancelBalanceStatus: (body) => axiosApi.put(`remittance/cancelBalanceStatus`, body),
  cancelExtracostStatus: (body) => axiosApi.put(`remittance/cancelExtracostStatus`, body),
  cancelReAdvanceStatus: (body) => axiosApi.put(`remittance/cancelReAdvanceStatus`, body),
  cancelRemittance: (body) => axiosApi.put(`remittance/cancelRemittance`, body),
  companyBodyRelations: (body) => axiosApi.post(`companyBodyRelations/save`, body),
  companyBodyRelationsUpdate: (body) => axiosApi.put(`companyBodyRelations/update`, body),
  companyCargoGeneratorRelation: (id) => axiosApi.get(`tariffs/relationTariffTaxFindById/${id}`),
  companyDriverRelations: (body) => axiosApi.post(`companyDriverRelations/save`, body),
  companyLoadTariffList: (id) => axiosApi.get(`/tariffs/companyLoadTariffList/${id}`),
  companyVehicleRelation: (body) => axiosApi.post(`companyVehicleRelation/save`, body),
  createBank: (header, body) => axiosApi.post(`master/loadGeneratorBankAccountRelation/saveByBankLoad`, header, body),
  createBankBeneficiaryPay: (body) => axiosApi.post(`beneficiaryPaymentBankAccountRelation/saveByBanckBeneficiary`, body),
  createBankCarriers: (body) => axiosApi.post(`driverBankAccountRelations/saveByBankDriveRelation`, body),
  createBankTransportCompanies: (body) => axiosApi.post(`transportCompanyBankAccountRelation/saveByCompanyToBank`, body),
  createBeneficiaryPay: (body) => axiosApi.post(`beneficiaryPayment/saveByBeneficiaryPayment`, body),
  createBodies: (body) => axiosApi.post(`masterBody/saveByMasterBody`, body),
  createBodiesInsurance: (body) => axiosApi.post(`bodyInsurances/save`, body),
  createBodiesObservation: (body) => axiosApi.post(`bodyObservations/save`, body),
  createBodiesPhoto: (body) => axiosApi.post(`bodyFileRelation/saveByFileToBody`, body),
  CreateCarriers: (body) => axiosApi.post(`masterDrivers/saveByMasterDrive`, body),
  createChargeGenerator: (header, body) => axiosApi.post(`masterLoadGenerator/saveByMasterLoadGenerator`, header, body),
  createCustomer: (body) => axiosApi.post(`masterClients/saveByClienteMaster`, body),
  createRates: (body) => axiosApi.post(`tariffs/saveNewTariff`, body),
  createTransportCompanies: (body) => axiosApi.post(`transportCompany/saveByMasterTransport`, body),
  createVehicle: (body) => axiosApi.post(`masterVehicles/saveByMasterVehicle`, body),
  dataBank: (header, id) => axiosApi.get(`master/loadGeneratorBankAccountRelation/findBankMasterLoadId/${id}`, header),
  dataBankCarriers: (id) => axiosApi.get(`driverBankAccountRelations/findBankDriveAllById/${id}`),
  dataInsuranceVehicle: (id) => axiosApi.get(`vehicleInsurances/findInsuranceId/${id}`),
  dataTableChargeGenerator: (header) => axiosApi.get(`masterLoadGenerator/findContacAndFileAll`, header),
  dataTableClients: (header) => axiosApi.get(`masterClients/findContacAndFileAll`, header),
  dataVehicle: () => axiosApi.get(`masterVehicles/findContacAll`),
  deleteBankTransportCompany: (id) => axiosApi.delete(`transportCompanyBankAccountRelation/delete/${id}`),
  deleteBodiesInsurance: (id) => axiosApi.delete(`bodyInsurances/delete/${id}`),
  deleteBodiesObservation: (id) => axiosApi.delete(`bodyObservations/delete/${id}`),
  deletedObservation: (id, header) => axiosApi.delete(`vehicleObservation/delete/${id}`, header),
  deleteInsuranceVehicle: (id) => axiosApi.delete(`vehicleInsurances/delete/${id}`),
  editBank: (header, body) => axiosApi.put(`master/loadGeneratorBankAccountRelation/updateByBankLoad`, body, header),
  editBankBeneficiaryPay: (body) => axiosApi.put(`beneficiaryPaymentBankAccountRelation/updateByBanckBeneficiary`, body),
  editBankCarriers: (body) => axiosApi.put(`driverBankAccountRelations/updateByBankDriveRelation`, body),
  editBankChargeGenerator: (body) => axiosApi.put(`master/loadGeneratorBankAccountRelation/updateByBankLoad`, body),
  editBeneficiaryPay: (body) => axiosApi.put(`beneficiaryPayment/updateByBeneficiaryPayment`, body),
  editCarriers: (body) => axiosApi.put(`masterDrivers/updateByMasterDrive`, body),
  editChargeGenerator: (header, body) => axiosApi.put(`masterLoadGenerator/updateMasterLoad`, header, body),
  editCustomer: (body) => axiosApi.put(`masterClients/updateByMasterClient`, body),
  editObservation: (body) => axiosApi.put(`vehicleObservation/update`, body),
  editVehicle: (body) => axiosApi.put(`masterVehicles/updateByMasterVehicle`, body),
  findByKeysList: (body) => axiosApi.post(`parameters/findByKeysList`, body),
  findContractByIdAll: ({ id, consignment }) => axiosApi.get(`contracts/findContractByIdAll/${id}/${consignment}`),
  findToken: (token) => axiosApi.get(`userTable/findToken/${token}`),
  generateReportInExcel: (body,header) => axiosApi.post(`contracts/generateReportInExcel`, body,header),
  geocode: ({ apiKey, latlng }) => axiosApi.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${apiKey}`),
  getadvanceBalanceBeneficiaryPayment: (id) => axiosApi.get(`contracts/getadvanceBalanceBeneficiaryPayment/${id}`),
  getadvanceBalanceTravelInformation: ({ idContract, remittance }) => axiosApi.get(`contracts/getadvanceBalanceTravelInformation/${idContract}/${remittance}`),
  getAllByIdFinancialManagementNewTrip: (id) => axiosApi.get(`contracts/getAllByIdFinancialManagementNewTrip/${id}`),
  getAllByIdFinancialNewTripToByMasterTransportById: (id) => axiosApi.get(`contracts/getAllByIdFinancialNewTripToByMasterTransportById/${id}`),
  getAllByIdNewTripPost: () => axiosApi.get(`contracts/getAllByIdNewTripPost/0`),
  getAlltreasuryPortfolioStatus: () => axiosApi.get(`contracts/getAlltreasuryPortfolioStatus/0`),
  getBlogPublicationsBitacors: () => axiosApi.get(`contracts/getBlogPublicationsBitacors/0`),
  getByPointRemittanceId: (id) => axiosApi.get(`loadingUnloadingPoints/getByPointRemittanceId/${id}`),
  getcalculateRemittanceAvailability: (body) => axiosApi.post(`contracts/getcalculateRemittanceAvailability`, body),
  getcontractCompanyResult: (id) => axiosApi.get(`contracts/getcontractCompanyResult/${id}`),
  getcontractDistributionList: () => axiosApi.get(`contracts/getcontractDistributionList`),
  getCurrentInterestYieldArrearsResult: ({contractId,cup,cupMorum}) => axiosApi.get(`/contracts/getCurrentInterestYieldArrearsResult/${contractId}/${cup}/${cupMorum}`),
  getEvidenceLoadedStatus: ({ idLoadUnload, status }) => axiosApi.get(`contracts/getEvidenceLoadedStatus/${idLoadUnload}/${status}`),
  getMaintenanceRemittances: (id) => axiosApi.get(`contracts/getMaintenanceRemittances/${id}`),
  getMasterTransportDriverActive: (companyId) => axiosApi.get(`masterDrivers/getMasterTransportDriverActive/${companyId}`),
  getMasterTransportVehicleActive: (transpotCompanyId) => axiosApi.get(`transportCompany/getMasterTransportVehicleActive/${transpotCompanyId}`),
  getobtainCarrierBalanceAdvances: (id) => axiosApi.get(`contracts/getobtainCarrierBalanceAdvances/${id}`),
  getObtainCarrierBalanceAdvancesTable: (id) => axiosApi.get(`contracts/getObtainCarrierBalanceAdvances/${id}`),
  approveShipmentLoad: (body) => axiosApi.put(`loadingUnloadingPoints/approveShipmentLoad`,body),
  getObtainCompanyThroughThirdParty: () => axiosApi.get(`contracts/getObtainCompanyThroughThirdParty/0`),
  getobtainContractsMaestroTransporterShipments: (id) => axiosApi.get(`contracts/getobtainContractsMaestroTransporterShipments/${id}`),
  getobtainExtraCostsInformation: (id) => axiosApi.get(`contracts/getobtainExtraCostsInformation/${id}`),
  getobtainRemittancesClosedByContract: (id) => axiosApi.get(`contracts/getobtainRemittancesClosedByContract/${id}`),
  getobtainTransportCompanyCalculations: (id) => axiosApi.get(`contracts/getobtainTransportCompanyCalculations/${id}`),
  getPaidCalculations: (id) => axiosApi.get(`contracts/getPaidCalculations/${id}`),
  getPaymentSummaryPayee: (id) => axiosApi.get(`contracts/getPaymentSummaryPayee/${id}`),
  getRateTypeVehicle: () => axiosApi.get(`tariffs/getRateTypeVehicle`),
  getRateVehicleByVehicleType: (vehicleType) => axiosApi.get(`contracts/getRateVehicleByVehicleType/${vehicleType}`),
  getRateVehicleTypeByIdAll: () => axiosApi.get(`contracts/getRateVehicleTypeByIdAll/0`),
  getTaxesChargesByIdAll: () => axiosApi.get(`contracts/getTaxesChargesByIdAll/0`),
  gettreasuryLiquidationPortfolio: () => axiosApi.get(`contracts/gettreasuryLiquidationPortfolio/0`),
  getTreasurySettlementsAdvancesBalancePayments: () => axiosApi.get(`contracts/getTreasurySettlementsAdvancesBalancePayments/0`),
  liquidateRemittance: (body) => axiosApi.put(`remittance/liquidateRemittance`, body),
  liquidateTreasuryContract: (body) => axiosApi.put(`contracts/liquidateTreasuryContract`, body),
  listObservationesVehicle: (id) => axiosApi.get(`vehicleObservation/findObservationId/${id}`),
  loadBankBeneficiaryPay: (id) => axiosApi.get(`beneficiaryPaymentBankAccountRelation/findBeneficiaryBankByIdAll/${id}`),
  loadBankTransportCompanies: (id) => axiosApi.get(`transportCompanyBankAccountRelation/findCompanyBankByIdAll/${id}`),
  loadBeneficiaryPay: () => axiosApi.get(`beneficiaryPayment/findBeneficiaryAll`),
  loadBodies: () => axiosApi.get(`masterBody/findBodyAll`),
  loadBodiesInsurance: (id) => axiosApi.get(`bodyInsurances/findBodyInsuranceByIdAll/${id}`),
  loadBodiesObservation: (id) => axiosApi.get(`bodyObservations/findBodyObservationByIdAll/${id}`),
  loadBodiesPhoto: (id) => axiosApi.get(`bodyFileRelation/findBodyFileByIdAll/${id}`),
  loadCarriers: () => axiosApi.get(`masterDrivers/findContacAndFileAll`),
  loadingUnloadingPoints: (body) => axiosApi.post(`loadingUnloadingPoints/save`, body),
  loadingUnloadingPointsDelete: (body) => axiosApi.put(`loadingUnloadingPoints/blockPointLoadAndUnload`, body),
  loadRatesTables: () => axiosApi.get(`tariffs/findTariffByIdAll/0`),
  loadTransportCompanies: () => axiosApi.get(`transportCompany/findMasterTransporAll`),
  loadTransportCompany: () => axiosApi.get(`transportCompany/findAll`),
  loadVehiclePhoto: (id) => axiosApi.get(`vehicleFileRelations/findVehicleFileAll/${id}`),
  login: (body) => axiosApi.post('login', body),
  masterDrivers: () => axiosApi.get(`masterDrivers/findContacAndFileAll`),
  masterLoadGenerator: () => axiosApi.get(`masterLoadGenerator/findActiveAll`),
  masterVehicles: () => axiosApi.get(`masterVehicles/findAll`),
  payExtracostStatus: (body) => axiosApi.put(`remittance/payExtracostStatus`, body),
  payReAdvanceStatus: (body) => axiosApi.put(`remittance/payReAdvanceStatus`, body),
  payRemittance: (body) => axiosApi.put(`remittance/payRemittance`, body),
  pushNotification: (body) => axiosApi.post(`parameters/pushNotification`, body),
  saveImgVehicle: (body) => axiosApi.post(`vehicleFileRelations/saveByVeichleFile`, body),
  saveInsuranceVehicle: (body) => axiosApi.post(`vehicleInsurances/save`, body),
  saveObservationVehicle: (body) => axiosApi.post(`vehicleObservation/save`, body),
  saveOrUpdateFinancialManagementNewTrip: (body) => axiosApi.post(`contracts/saveOrUpdateFinancialManagementNewTrip`, body),
  saveOrUpdateNewRemittance: (body) => axiosApi.post(`contracts/saveOrUpdateNewRemittance`, body),
  saveOrUpdateRateVehicleType: (body) => axiosApi.post(`tariffs/saveOrUpdateRateVehicleType`, body),
  sessionId: (id) => axiosApi.get(`sessions/find/${id}`),
  startNewSession: (body) => axiosApi.post(`sessions/startNewSession`, body),
  updateBankTransportCompany: (body) => axiosApi.put(`transportCompanyBankAccountRelation/updateByCompanyToBank`, body),
  updateBeneficiaryRelations: (body) => axiosApi.put(`beneficiaryPaymentCompanyRelation/update`, body),
  updateBodies: (body) => axiosApi.put(`masterBody/updateByMasterBody`, body),
  updateBodiesInsurance: (body) => axiosApi.put(`bodyInsurances/update`, body),
  updateBodiesObservation: (body) => axiosApi.put(`bodyObservations/update`, body),
  updateBodiesPhoto: (body) => axiosApi.put(`bodyFileRelation/updateByFileToBody`, body),
  updateByLoadUnload: (body) => axiosApi.put(`loadingUnloadingPoints/updateByLoadUnload`, body),
  UpdatecompanyDriverRelations: (body) => axiosApi.put(`companyDriverRelations/update`, body),
  updateCompanyVehicleRelation: (body) => axiosApi.put(`companyVehicleRelation/update`, body),
  updateInsurance: (body,) => axiosApi.put(`vehicleInsurances/update`, body),
  updateOrSaveAdvancePayment: (body) => axiosApi.post(`contracts/updateOrSaveAdvancePayment`, body),
  updateOrSaveExtracost: (body) => axiosApi.post(`contracts/updateOrSaveExtracost`, body),
  updateOrSaveNewContract: (body) => axiosApi.post(`contracts/updateOrSaveNewContract`, body),
  UpdateRates: (body) => axiosApi.put(`tariffs/updateNewTariff`, body),
  updateStatusTariffs: (body) => axiosApi.put(`tariffs/updateStatus`, body),
  updateTaxChargePercentage: (body) => axiosApi.put(`tariffs/updateTaxChargePercentage`, body),
  updateTransportCompanies: (body) => axiosApi.put(`transportCompany/updateByMasterTransport`, body),
  updateVehiclePhoto: (body) => axiosApi.put(`vehicleFileRelations/updateByVeichleFile`, body),
  userId: (user) => axiosApi.get(`userTable/findUser/${user}`),
  vehicleDriverRelations: (body) => axiosApi.post(`vehicleDriverRelations/save`, body),
  vehicleDriverRelationsUpdate: (body) => axiosApi.put(`vehicleDriverRelations/update`, body),
}

export {
  parameterApi
}

