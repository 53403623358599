
import { parameterApi } from "@/api/parameterApi";

export const loadBeneficiaryPay = async ({ commit }) => {
  try {
    let { data } = await parameterApi.loadBeneficiaryPay()
    commit('setDataTables', data, false)
  } catch (error) {
    console.log(error)
    if (error.response.status == 422) {
      commit('setDataTables', [], false)
    }
  }
}
export const createBeneficiaryPay = async (_, body) => {
  try {
    let { data } = await parameterApi.createBeneficiaryPay(body)
    return { ok: true, id: data.id }
  } catch (error) {
    return { ok: false, message: error.response.data.message }
  }
}
export const editBeneficiaryPay = async (_, body) => {
  try {
    await parameterApi.editBeneficiaryPay(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}
export const createBnak = async (_, body) => {
  try {
     await parameterApi.createBankBeneficiaryPay(body)
    return { ok: true } 
  } catch (error) {
    console.log(error)
    return{ ok: false, message: error.response.data.message}
  }
}

export const editBank = async (_, body) => {
  try {
     await parameterApi.editBankBeneficiaryPay(body)
    return  { ok: true } 
  } catch (error) {
    console.log(error)
    return{ ok: false, message: error.response.data.message}
  }
}

export const dataBank = async (_, id) => {
  try {
    let { data } = await parameterApi.loadBankBeneficiaryPay(id)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const BeneficiaryRelations = async (_, body) => {
  try {
    await parameterApi.BeneficiaryRelations(body)
  } catch (error) {
    console.log(error)
  }
}
export const updateBeneficiaryRelations = async (_, body) => {
  try {
    await parameterApi.updateBeneficiaryRelations(body)
  } catch (error) {
    console.log(error)
  }
}

export const getPaymentSummaryPayee = async (_, id) => {
  try {
  const { data } =  await parameterApi.getPaymentSummaryPayee(id)
    return {data}
  } catch (error) {
    console.log(error)
  }
}
export const beneficiaryPaymentBalanceummaryPayee = async (_, id) => {
  try {
  const { data } =  await parameterApi.beneficiaryPaymentBalanceummaryPayee(id)
    return {data}
  } catch (error) {
    console.log(error)
  }
}