import { createStore } from 'vuex'
import carriers from '../modules/pagues/carriers/store'
import beneficiaryPay from '../modules/pagues/beneficiaryPay/store'
import rates from '../modules/pagues/rates/store'
import bodies from '../modules/pagues/bodies/store'
import globalPagues from '../modules/pagues/store/index'
import vehicle from '../modules/pagues/vehicle/store'
import transportCompanies from '../modules/pagues/transportCompanies/store'
import customer from '../modules/pagues/customer/store'
import chargeGenerators from '../modules/pagues/chargeGenerators/store'
import binnacle from '../modules/pagues/operations/binnacle/store'
import treasurySettlements from '../modules/pagues/treasury/treasurySettlements/store'
import account from '../modules/account/store'
import managementAdvancesBalances from '../modules/pagues/operations/managementAdvancesBalances/store'
import treasuryPortfolio from '../modules/pagues/treasury/treasuryPortfolio/store'
import portfolioLiquidation from '../modules/pagues/treasury/portfolioLiquidation/store'
export default createStore({
  modules: {
    carriers,
    beneficiaryPay,
    bodies,
    portfolioLiquidation,
    vehicle,
    account,
    rates,
    binnacle,
    chargeGenerators,
    treasurySettlements,
    transportCompanies,
    globalPagues,
    customer,
    managementAdvancesBalances,
    treasuryPortfolio
  },
})
