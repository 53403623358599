<template>
  <router-view />
</template>

<script>
import { onMounted } from "vue";
import useMethods from "./modules/account/composables/useMethods";
export default {
  setup() {
    const { findToken, checkSignOff } = useMethods();

    onMounted(async () => {
      let toke = localStorage.getItem("token_session");
      if (toke) {
        const respToken = await findToken(toke);
        if (respToken == "false") {
          checkSignOff();
        }
      }
    });
  },
};
</script>

<style>
</style>
