import axios from 'axios';

const baseURL = process.env.VUE_APP_BACKEND_URL;
const axiosApi = axios.create({
  baseURL,
});

axiosApi.interceptors.request.use((config) => {
  const headers = localStorage.getItem('token_session')
  if (headers) {
    config.headers.Authorization = `Bearer ${headers}`;
    return config;
  }
  return config;
});

export default axiosApi;

