
import { parameterApi } from "@/api/parameterApi";
import { saveAs } from 'file-saver';

export const gettreasuryLiquidationPortfolio = async ({ commit }) => {
    try {
        let { data } = await parameterApi.gettreasuryLiquidationPortfolio();
        commit('setDataTables', data, false)
    } catch (error) {
        console.log(error)
        if (error.response.status == 422) {
            commit('setDataTables', [], false)
        }
    }
}
export const getobtainRemittancesClosedByContract = async (_, id) => {
    try {
        let { data } = await parameterApi.getobtainRemittancesClosedByContract(id);
        return data
    } catch (error) {
        console.log(error)

    }
}


export const generateReportInExcel = async (_, body) => {
    try {
        const { data } = await parameterApi.generateReportInExcel(body, {
            responseType: 'arraybuffer',
        });

        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, 'Gocargo Reportes.xlsx');

        return { ok: true };
    } catch (error) {
        console.error(error);
        return { ok: false, message: error.response.data.message };
    }
};