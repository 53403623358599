import { createRouter, createWebHistory } from 'vue-router'
import isAuthenticatedGuard from './auth-guard';
const routes = [

  {
    path: '/',
    redirect: '/pagues/transportCompanies'
  },

  {
    beforeEnter: [isAuthenticatedGuard],
    path: '/pagues',
    name: 'pagues',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pagesMaster.vue'),
    children: [
      {
        path: 'transportCompanies',
        name: 'transportCompanies',
        component: () => import(/* webpackChunkName: "transportCompanies" */ '@/modules/pagues/transportCompanies/indexPague.vue')
      },
      {
        path: 'customer',
        name: 'customer',
        component: () => import(/* webpackChunkName: "customer" */ '@/modules/pagues/customer/indexPague.vue')
      },
      {
        path: 'vehicle',
        name: 'vehicle',
        component: () => import(/* webpackChunkName: "vehicle" */ '@/modules/pagues/vehicle/indexPague.vue')
      },
      {
        path: 'chargeGenerators',
        name: 'chargeGenerators',
        component: () => import(/* webpackChunkName: "chargeGenerators" */ '@/modules/pagues/chargeGenerators/indexPague.vue')
      },
      {
        path: 'bodies',
        name: 'bodies',
        component: () => import(/* webpackChunkName: "bodies" */ '@/modules/pagues/bodies/indexPague.vue')
      },
      {
        path: 'rates',
        name: 'rates',
        component: () => import(/* webpackChunkName: "bodies" */ '@/modules/pagues/rates/indexPague.vue')
      },
      {
        path: 'carriers',
        name: 'carriers',
        component: () => import(/* webpackChunkName: "carriers" */ '@/modules/pagues/carriers/indexPague.vue')
      },
      {
        path: 'beneficiaryPay',
        name: 'beneficiaryPay',
        component: () => import(/* webpackChunkName: "beneficiaryPay" */ '@/modules/pagues/beneficiaryPay/indexPague.vue')
      },
      {
        path: 'operations',
        name: 'operations',
        children: [
          {
            path: 'binnacle',
            name: 'binnacle',
            component: () => import(/* webpackChunkName: "binnacle" */ '@/modules/pagues/operations/binnacle/indexPague.vue')
          },
          {
            path: 'managementAdvancesBalances',
            name: 'managementAdvancesBalances',
            component: () => import(/* webpackChunkName: "managementAdvancesBalances" */ '@/modules/pagues/operations/managementAdvancesBalances/indexPague.vue')
          }
        ]
      },
      {
        path: 'treasury',
        name: 'treasury',
        children: [
          {
            path: 'treasurySettlements',
            name: 'treasurySettlements',
            component: () => import(/* webpackChunkName: "bodies" */ '@/modules/pagues/treasury/treasurySettlements/indexPague.vue')
          },
          {
            path: 'treasuryPortfolio',
            name: 'treasuryPortfolio',
            component: () => import(/* webpackChunkName: "bodies" */ '@/modules/pagues/treasury/treasuryPortfolio/indexPague.vue')
          },
          {
            path: 'portfolioLiquidation',
            name: 'portfolioLiquidation',
            component: () => import(/* webpackChunkName: "bodies" */ '@/modules/pagues/treasury/portfolioLiquidation/indexPague.vue')
          },
        ],

      },
    ]
  },

  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '@/views/accountMaster.vue'),
    children: [
      {
        path: 'formSession',
        name: 'formSession',
        component: () => import(/* webpackChunkName: "formSession" */ '@/modules/account/formSession.vue')
      },
      {
        path: 'recoverKey',
        component: () => import(/* webpackChunkName: "recoverKey" */ '@/modules/account/recoverKey.vue')
      },
      {
        path: 'recoveryCode',
        component: () => import(/* webpackChunkName: "recoveryCode" */ '@/modules/account/recoveryCode.vue')
      },
      {
        path: 'formIncomeRecovery',
        component: () => import(/* webpackChunkName: "formIncomeRecovery" */ '@/modules/account/formIncomeRecovery.vue')
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
