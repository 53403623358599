import { parameterApi } from "@/api/parameterApi"

export const BlogPublicationsBitacors = async ({ commit }) => {
  try {
    let { data } = await parameterApi.getBlogPublicationsBitacors()
    commit('setDataTables', data, false)
  } catch (error) {
    console.log(error)
    if (error.response.status == 422) {
      commit('setDataTables', [], false)
    }
  }
}

export const contractDistributionList = async () => {
  try {
    let { data } = await parameterApi.getcontractDistributionList()
    return data
  } catch (error) {
    console.log(error)
  }
}
export const findContractByIdAll = async (_, idConsignment) => {
  try {
    let { data } = await parameterApi.findContractByIdAll(idConsignment)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const saveOrUpdateNewRemittance = async (_, body) => {
  try {
    let { data } = await parameterApi.saveOrUpdateNewRemittance(body)
    return { ok: true, data }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }

  }
}

export const loadingUnloadingPoints = async (_, body) => {
  try {
    await parameterApi.loadingUnloadingPoints(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }

  }
}
export const saveOrUpdateFinancialManagementNewTrip = async (_, body) => {
  try {
    const { data } = await parameterApi.saveOrUpdateFinancialManagementNewTrip(body)
    return { ok: true, data: data }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }

  }
}
export const updateByLoadUnload = async (_, body) => {
  try {
    await parameterApi.updateByLoadUnload(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }

  }
}

export const ByPointRemittanceId = async (_, id) => {
  try {
    let { data } = await parameterApi.getByPointRemittanceId(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const ObtainCompanyThroughThirdParty = async () => {
  try {
    let { data } = await parameterApi.getObtainCompanyThroughThirdParty()
    return data
  } catch (error) {
    console.log(error)
  }
}
export const AllByIdFinancialNewTripToByMasterTransportById = async (_, id) => {
  try {
    let { data } = await parameterApi.getAllByIdFinancialNewTripToByMasterTransportById(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const getRateVehicleByVehicleType = async (_, id) => {
  try {
    let { data } = await parameterApi.getRateVehicleByVehicleType(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const loadingUnloadingPointsDelete = async (_, body) => {
  try {
    let { data } = await parameterApi.loadingUnloadingPointsDelete(body)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const getAllByIdFinancialManagementNewTrip = async (_, id) => {
  try {
    let { data } = await parameterApi.getAllByIdFinancialManagementNewTrip(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const getMaintenanceRemittances = async (_, id) => {
  try {
    let { data } = await parameterApi.getMaintenanceRemittances(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const getcalculateRemittanceAvailability = async (_, body) => {
  try {
    let { data } = await parameterApi.getcalculateRemittanceAvailability(body)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const pushNotification = async (_, body) => {
  try {
    await parameterApi.pushNotification(body)
  } catch (error) {
    console.log(error)
  }
}