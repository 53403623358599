
import { parameterApi } from "@/api/parameterApi";

export const getAlltreasuryPortfolioStatus = async ({ commit }) => {
    try {
        let { data } = await parameterApi.getAlltreasuryPortfolioStatus();
        commit('setDataTables', data, false)
    } catch (error) {
        console.log(error)
        if (error.response.status == 422) {
            commit('setDataTables', [], false)
        }
    }
}

export const liquidateRemittance = async (_, body) => {
    try {
        await parameterApi.liquidateRemittance(body);
        return {
            ok: true
        }
    } catch (error) {
        return {
            ok: true, message: error.response.data.message
        }
    }
}
export const liquidateTreasuryContract = async (_, body) => {
    try {
        await parameterApi.liquidateTreasuryContract(body);
        return {
            ok: true
        }
    } catch (error) {
        return {
            ok: true, message: error.response.data.message
        }
    }
}
export const getCurrentInterestYieldArrearsResult = async (_, id) => {
    try {
        const { data } = await parameterApi.getCurrentInterestYieldArrearsResult(id);
        return data
    } catch (error) {
        console.log(error)
        return []
    }
}