import { parameterApi } from "@/api/parameterApi";

export const loadCarriers = async ({ commit }) => {
  try {
    let { data } = await parameterApi.loadCarriers()
    commit('setDataTables', data, false)
  } catch (error) {
    console.log(error)
    if (error.response.status == 422) {
      commit('setDataTables', [], false)
    }
  }
}

export const CreateCarriers = async (_, infoCarriers) => {
  try {
    let { data } = await parameterApi.CreateCarriers(infoCarriers)
    return { ok: true, id: data.id }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}

export const dataBankCarriers = async (_, id) => {
  try {
    let { data } = await parameterApi.dataBankCarriers(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const createBankCarriers = async (_, dataBank) => {
  try {
    await parameterApi.createBankCarriers(dataBank)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}
export const editCarriers = async (_, dataCarrier) => {
  try {
    await parameterApi.editCarriers(dataCarrier)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}
export const editBankCarriers = async (_, dataBank) => {
  try {
    await parameterApi.editBankCarriers(dataBank)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}
export const companyDriverRelations = async (_, body) => {
  try {
    await parameterApi.companyDriverRelations(body)
  } catch (error) {
    console.log(error)
  }
}
export const UpdatecompanyDriverRelations = async (_, body) => {
  try {
    await parameterApi.UpdatecompanyDriverRelations(body)
  } catch (error) {
    console.log(error)
  }
}
export const getobtainCarrierBalanceAdvances = async (_, id) => {
  try {
    const { data } = await parameterApi.getobtainCarrierBalanceAdvances(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const getObtainCarrierBalanceAdvancesTable = async (_, id) => {
  try {
    const { data } = await parameterApi.getObtainCarrierBalanceAdvancesTable(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const getobtainExtraCostsInformation = async (_, id) => {
  try {
    const { data } = await parameterApi.getobtainExtraCostsInformation(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
