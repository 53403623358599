import { parameterApi } from "@/api/parameterApi"

export const loadVehicle = async ({ commit }) => {
  try {
    let { data } = await parameterApi.dataVehicle()
    commit('setDataTables', data, false)
  } catch (error) { 
    console.log(error)
    if (error.response.status == 422) {
      commit('setDataTables', [], false)
    }
  }
}

export const createVehicle = async (_, body) => {
  try {
    let { data } = await parameterApi.createVehicle(body)
    return { ok: true, id: data.id }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }

  }
}

export const createPhoto = async (_, photo) => {
  try {
    await parameterApi.saveImgVehicle(photo)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}
export const loadObservation = async (_, id) => {
  try {
    let { data } = await parameterApi.listObservationesVehicle(id)
    return data
  } catch (error) {
    console.log(error)
    return []
  }
}
export const getMasterTransportDriverActive = async (_, id) => {
  try {
    let { data } = await parameterApi.getMasterTransportDriverActive(id)
    return data
  } catch (error) {
    console.log(error)
    return []
  }
}

export const createObservation = async (_, observation) => {
  try {
    await parameterApi.saveObservationVehicle(observation);
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}
export const loadInsurance = async (_, id) => {
  try {
    let { data } = await parameterApi.dataInsuranceVehicle(id)
    return data
  } catch (error) {
    console.log(error)
    return []
  }
}

export const createInsurance = async (_, insurance) => {
  try {
    await parameterApi.saveInsuranceVehicle(insurance)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}
export const updateVehicle = async (_, body) => {
  try {
    await parameterApi.editVehicle(body)
    return { ok: true, }
  } catch (error) {
    console.log(error)
     
    return { ok: false, message: error.response.data.message }
  }
}

export const loadPhoto = async (_, id) => {
  try {
    let { data } = await parameterApi.loadVehiclePhoto(id)
    return data
  } catch (error) {
    console.log(error)
    return []
  }
}


export const updatePhoto = async (_, photo) => {
  try {
    await parameterApi.updateVehiclePhoto(photo)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}

export const deleteObservation = async (_, id) => {
  try {
    await parameterApi.deletedObservation(id)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}
export const updateObservation = async (_, body) => {
  try {
    await parameterApi.editObservation(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}
export const deleteInsurance = async (_, body) => {
  try {
    await parameterApi.deleteInsuranceVehicle(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}

export const UpdateInsurance = async (_, data) => {
  try {
    await parameterApi.updateInsurance(data)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}
export const companyVehicle = async (_, data) => {
  try {
    await parameterApi.companyVehicleRelation(data)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}
export const driverVehicle = async (_, data) => {
  try {
    await parameterApi.vehicleDriverRelations(data)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}
export const updateCompanyVehicle = async (_, data) => {
  try {
    await parameterApi.updateCompanyVehicleRelation(data)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}
export const vehicleDriverRelationsUpdate = async (_, data) => {
  try {
    await parameterApi.vehicleDriverRelationsUpdate(data)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false }
  }
}
export const getRateVehicleTypeByIdAll = async () => {
  try {
    let { data } = await parameterApi.getRateVehicleTypeByIdAll()
    return data
  } catch (error) {
    console.log(error)
    return []
  }
}