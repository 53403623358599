
import { parameterApi } from "@/api/parameterApi";

export const getTreasurySettlementsAdvancesBalancePayments = async ({ commit }) => {
    try {
        let { data } = await parameterApi.getTreasurySettlementsAdvancesBalancePayments();
        commit('setDataTables', data, false)
    } catch (error) {
        console.log(error)
        if (error.response.status == 422) {
            commit('setDataTables', [], false)
        }
    }
}
export const payRemittance = async (_, body) => {
    try {
        await parameterApi.payRemittance(body);
        return {
            ok: true
        }
    } catch (error) {
        return {
            ok: false, message: error.response.data.message
        }
    }
}
export const payReAdvanceStatus = async (_, body) => {
    try {
        await parameterApi.payReAdvanceStatus(body);
        return {
            ok: true
        }
    } catch (error) {
        return {
            ok: false, message: error.response.data.message
        }
    }
}
export const payExtracostStatus = async (_, body) => {
    try {
        await parameterApi.payExtracostStatus(body);
        return {
            ok: true
        }
    } catch (error) {
        return {
            ok: false, message: error.response.data.message
        }
    }
}
export const getPaidCalculations = async (_, id) => {
    try {
        const { data } = await parameterApi.getPaidCalculations(id);
        if(data[id].extracostList){
            data[id].extracostList.sort((a, b) => a.extracostId - b.extracostId)
        }

        if(data[id].reAdvanceList){
            data[id].reAdvanceList.sort((a, b) => a.reAdvanceId - b.reAdvanceId)
        }
        return data
    } catch (error) {
        console.log(error)
    }
}

export const cancelExtracostStatus = async (_, body) => {
    try {
        await parameterApi.cancelExtracostStatus(body);
        return {
            ok: true
        }
    } catch (error) {
        return {
            ok: false, message: error.response.data.message
        }
    }
}
export const cancelReAdvanceStatus = async (_, body) => {
    try {
        await parameterApi.cancelReAdvanceStatus(body);
        return {
            ok: true
        }
    } catch (error) {
        return {
            ok: false, message: error.response.data.message
        }
    }
}
export const cancelAdvanceStatus = async (_, body) => {
    try {
        await parameterApi.cancelAdvanceStatus(body);
        return {
            ok: true
        }
    } catch (error) {
        return {
            ok: false, message: error.response.data.message
        }
    }
}
export const cancelBalanceStatus = async (_, body) => {
    try {
        await parameterApi.cancelBalanceStatus(body);
        return {
            ok: true
        }
    } catch (error) {
        return {
            ok: false, message: error.response.data.message
        }
    }
}