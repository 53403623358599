
import { parameterApi } from "@/api/parameterApi";
import router from '@/router';
import axios from "axios";

export const getUserId = async () => {
  try {
    let user = localStorage.getItem('user')
    let { data } = await parameterApi.userId(user)
    localStorage.setItem('user_id', data.id)
  } catch (error) {
    console.log(error)
  }
}

// export const getsessionId = async () => {
//   try {
//     let id = localStorage.getItem('user_id')
//     let { data } = await parameterApi.sessionId(id)
//     localStorage.setItem('session_id', data[0].id)
//   } catch (error) {
//     console.log(error)
//   }
// }

export const startNewSession = async (_, body) => {
  try {
  const {data} = await parameterApi.startNewSession(body)
  localStorage.setItem('session_id', data.id)
  } catch (error) {
    console.log(error)
  }
}


export const getLocalIpAddress = async () => {
  try {
    const { data } = await axios.get('https://api.ipify.org?format=json');
    return data.ip;
  } catch (error) {
    console.error(error);
  }
};
export const findToken = async (_,token) => {
  try {
    const { data } = await parameterApi.findToken(token);
    return data.message;
  } catch (error) {
    console.error(error);
    return error.response.data.message;
  }
};

export const signIn = async (_, user) => {
  try {
    let { data } = await parameterApi.login({
      "user": user.user,
      "password": user.password
    })
    if (data.ok === true) {
      localStorage.setItem('token_session', data.token)
      localStorage.setItem('user', data.user)
      router.push('/')
    }

    return {
      ok: true
    }
  } catch (error) {
    console.log(error)
    return {
      ok: false,
      message: error.response.data.mensaje
    }
  }
}

export const SignOff = () => {
  router.push('/account/formSession')
  localStorage.removeItem('token_session')
  localStorage.removeItem('session_id')
  localStorage.removeItem('user')
  localStorage.removeItem('user_id')
}
