
import { useStore } from 'vuex'
import { togglePasswordField } from "@/utilities/functionsForm";
import { ref } from 'vue';
const useMethods = () => {
    const store = useStore()
    const passwordInput = ref(null);

    const checkSignIn = async (body) => {
        const resp = await store.dispatch('account/signIn', body)
        return resp
    }
    const checkTetUserId = async () => {
        await store.dispatch('account/getUserId')
    }
    const checkGetsessionId = async () => {
        await store.dispatch('account/getsessionId')
    }
    const checkSignOff = async () => {
        await store.dispatch('account/SignOff')
    }
    const checkStartNewSession = async (body) => {
        await store.dispatch('account/startNewSession', body)
    }

    const inputText = (id) => {
        let element = document.querySelector("#eyePassword");
        if (document.querySelector(".icon-eye")) {
            togglePasswordField(id);
            element.classList.remove("icon-eye");
            element.classList.add("icon-blocked_eye");
        } else {
            togglePasswordField(id);
            element.classList.add("icon-eye");
            element.classList.remove("icon-blocked_eye");
        }
    };


    const getIPAddress = async () => {
        const ipAddress = await store.dispatch('account/getLocalIpAddress')
        return ipAddress
    }
    const findToken = async (token) => {
        const resp = await store.dispatch('account/findToken',token)
        return resp
    }


    const moveToNextInput = (event) => {
        event.preventDefault();
        passwordInput.value.focus();
    };

    return {
        checkSignIn,
        inputText,
        passwordInput,
        findToken,
        moveToNextInput,
        checkTetUserId,
        checkSignOff,
        checkStartNewSession,
        getIPAddress,
        checkGetsessionId,
    }
}

export default useMethods