import { parameterApi } from "@/api/parameterApi"

export const getAllByIdNewTripPost = async ({ commit }) => {
  try {
    let { data } = await parameterApi.getAllByIdNewTripPost()
    commit('setDataTables', data, false)
  } catch (error) {
    console.log(error)
    if (error.response.status == 422) {
      commit('setDataTables', [], false)
    }
  }
}



export const approveRemittance = async (_, body) => {
  try {
    await parameterApi.approveRemittance(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}
export const cancelRemittance = async (_, body) => {
  try {
    await parameterApi.cancelRemittance(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }

  }
}

export const updateOrSaveAdvancePayment = async (_, body) => {
  try {
    await parameterApi.updateOrSaveAdvancePayment(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}
export const updateOrSaveExtracost = async (_, body) => {
  try {
    await parameterApi.updateOrSaveExtracost(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}

export const getadvanceBalanceBeneficiaryPayment = async (_, id) => {
  try {
    const { data } = await parameterApi.getadvanceBalanceBeneficiaryPayment(id)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const getadvanceBalanceTravelInformation = async (_, parameters) => {
  try {
    const { data } = await parameterApi.getadvanceBalanceTravelInformation(parameters)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const getEvidenceLoadedStatus = async (_, parameters) => {
  try {
    const { data } = await parameterApi.getEvidenceLoadedStatus(parameters)
    if (Object.keys(data).length === 0) {
      return false
    }
    return data
  } catch (error) {
    console.log(error)

  }
}
export const approveShipmentLoad = async (_, body) => {
  try {
    await parameterApi.approveShipmentLoad(body)
    return { ok: true }
  } catch (error) {
    console.log(error)
    return { ok: false, message: error.response.data.message }
  }
}