

export const TransportCompany = (state, data) => {
    state.TransportCompany = data
}
export const MasterDrivers = (state, data) => {
    state.MasterDrivers = data
}
export const vehicle = (state, data) => {
    state.vehicle = data
}
export const generator = (state, data) => {
    state.generator = data
}
