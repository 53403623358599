import { parameterApi } from "@/api/parameterApi";

export const loadChargeGenerator = async ({ commit }) => {
    try {
        let { data } = await parameterApi.dataTableChargeGenerator();
        commit('setDataTables', data, false)
    } catch (error) {
    console.log(error)
    if (error.response.status == 422) {
            commit('setDataTables', [], false)
        }
    }
}

