export function togglePasswordField(id) {
  var passwordField = document.getElementById(`${id}`);
  if (passwordField.type === "password") {
    passwordField.type = "text";
  } else {
    passwordField.type = "password";
  }
}


export function handleCodeInput(index, codeInputValue) {
  var codeBoxes = document.getElementsByClassName("code-box");
  var value = codeInputValue;
  if (value.length > index) {
    value = value.substring(0, index) + value.substring(index + 1);
  }
  codeBoxes[index].value = event.target.value;
  value = value.substring(0, index) + event.target.value + value.substring(index);

  if (index > 0 && event.target.value === "") {
    codeBoxes[index - 1].focus();
  } else if (index < codeBoxes.length - 1 && event.target.value !== "") {
    codeBoxes[index + 1].focus();
  }
  return value;
}


export function togglePasswordFields(idSpan, idInput, IdConfirm) {
  const iconElements = document.querySelectorAll(`#${idSpan}`);
  const passwordField = document.getElementById(idInput);
  const confirmPasswordField = document.getElementById(IdConfirm);

  if (passwordField.type === "password") {
    passwordField.type = "text";
    confirmPasswordField.type = "text";
    iconElements.forEach((iconElement) => {
      iconElement.classList.remove("icon-eye");
      iconElement.classList.add("icon-blocked_eye");
    });
  } else {
    passwordField.type = "password";
    confirmPasswordField.type = "password";
    iconElements.forEach((iconElement) => {
      iconElement.classList.add("icon-eye");
      iconElement.classList.remove("icon-blocked_eye");
    });
  }
}

